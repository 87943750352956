import {
  IGroupOrManufacturerListModel,
  IGroupOrManufacturerModel,
} from "shared/models/group/group-or-manufacturer.model";
import { GroupTypeFilterValues } from "../../components/group/group-filter/group-type-filter.component";
import { GroupContractType } from "../../models/group/group-contract.model";
import { IGroupModel } from "../../models/group/group.model";
import { INextGroupErpNumberModel } from "../../repositories/models/group/next-group-erp-number.model";
import { IGroup, INextGroupErpNumber } from "../group/group";
import { IGroupOrManufacturer, IGroupOrManufacturerList } from "../group/group-or-manufacturer";
import { GroupType } from "../group/group-type";
import { AddressConverter } from "./address.converter";
import { GroupBranchConverter } from "./group-branch.converter";
import { GroupContractConverter } from "./group-contract.converter";
import { PaymentInfoConverter } from "./payment-info.converter";

export class GroupConverter {
  public static toModel(group: IGroup): IGroupModel {
    const groupTypes = [group.type];

    return {
      id: group.id,
      addresses: group.addresses ? group.addresses.map(AddressConverter.toModel) : group.addresses,
      active: group.active ?? null,
      earNumber: group.earNumber ?? null,
      earTemporaryNumber: group.earTemporaryNumber ?? null,
      erpNumber: group.erpNumber ?? null,
      name: group.name,
      name2: group.name2,
      invoiceAddress: group.invoiceAddress ? AddressConverter.toModel(group.invoiceAddress) : group.invoiceAddress,
      groupAddress: group.groupAddress ? AddressConverter.toModel(group.groupAddress) : group.groupAddress,
      uuid: group.uuid,
      hasAcceptedSepaMandate: group.hasAcceptedSepaMandate,
      groupTypes: groupTypes,
      vatId: group.vatId ?? null,
      tradeRegisterNumber: group.tradeRegisterNumber ?? null,
      grsManagedEar: group.grsManagedEar,
      paymentInfo: group.paymentInfo ? PaymentInfoConverter.toModel(group.paymentInfo) : null,
      agencyGroups: null,
      cfmContracts: group.cfmContracts
        ? group.cfmContracts.map(GroupContractConverter.cfmContractToModel)
        : group.cfmContracts,
      pomContracts: group.pomContracts
        ? group.pomContracts.map(GroupContractConverter.pomContractToModel)
        : group.pomContracts,
      groupBranch: group.groupBranch ? GroupBranchConverter.toModel(group.groupBranch) : null,
      isPomAgencyAnnouncer: group.isPomAgencyAnnouncer,
      isAvalPartner: group.isAvalPartner ?? false,
    };
  }

  static toDomain(group: IGroupModel): IGroup {
    // for now, group can only support one group type
    const type = group.groupTypes[0];
    return {
      id: group.id,
      uuid: group.uuid,
      addresses: group.addresses ? group.addresses.map(AddressConverter.toDomain) : group.addresses,
      active: group.active,
      earNumber: group.earNumber ?? undefined,
      earTemporaryNumber: group.earTemporaryNumber ?? undefined,
      erpNumber: group.erpNumber ?? undefined,
      name: group.name ?? "",
      name2: group.name2,
      invoiceAddress: group.invoiceAddress ? AddressConverter.toDomain(group.invoiceAddress) : null,
      groupAddress: group.groupAddress ? AddressConverter.toDomain(group.groupAddress) : null,
      type,
      vatId: group.vatId ?? undefined,
      tradeRegisterNumber: group.tradeRegisterNumber ?? undefined,
      grsManagedEar: group.grsManagedEar ?? false,
      hasAcceptedSepaMandate: group.hasAcceptedSepaMandate,
      paymentInfo: group.paymentInfo,
      agencyGroup:
        group.agencyGroups?.length === 1 && group.agencyGroups[0] != null
          ? GroupConverter.toDomain(group.agencyGroups[0])
          : undefined,
      users: undefined,
      cfmContracts: group.cfmContracts
        ? group.cfmContracts.map(GroupContractConverter.cfmContractToDomain)
        : group.cfmContracts,
      pomContracts: group.pomContracts
        ? group.pomContracts.map(GroupContractConverter.pomContractToDomain)
        : group.pomContracts,
      groupBranch: group.groupBranch ? GroupBranchConverter.toDomain(group.groupBranch) : null,
      isPomAgencyAnnouncer: group.isPomAgencyAnnouncer,
      isAvalPartner: group.isAvalPartner ?? false,
    };
  }

  static nextErpNumberToDomain(data: INextGroupErpNumberModel): INextGroupErpNumber {
    return {
      nextErpNumber: data.nextErpNumber,
    };
  }

  public static groupOrManufacturerToDomain(model: IGroupOrManufacturerModel): IGroupOrManufacturer {
    return {
      id: model.id,
      manufacturerId: model.manufacturerId,
      representative: model.representative,
      groupType: model.groupTypes?.[0] ?? null,
      active: model.active,
      displayAddress: model.displayAddress,
      addresses: model.addresses,
      name: model.name,
      erpNumber: model.erpNumber,
      hasActiveUsers: model.hasActiveUsers,
    };
  }

  public static groupOrManufacturerListToDomain(model: IGroupOrManufacturerListModel): IGroupOrManufacturerList {
    return {
      meta: {
        page: model.meta.page,
        numberOfPages: model.meta.numberOfPages,
        numberOfRecords: model.meta.numberOfRecords,
        pageSize: model.meta.pageSize,
      },
      items: model.items.map(GroupConverter.groupOrManufacturerToDomain),
    };
  }

  public static groupTypesToGroupTypeFilterValues(
    groupType: GroupType,
    contractTypes?: GroupContractType[],
  ): GroupTypeFilterValues | undefined {
    switch (groupType) {
      case GroupType.Customer:
        if (contractTypes?.includes(GroupContractType.Pom) && contractTypes?.includes(GroupContractType.Cfm)) {
          return GroupTypeFilterValues.PomAndCfmCustomer;
        }
        if (contractTypes?.includes(GroupContractType.Pom)) {
          return GroupTypeFilterValues.Pom;
        }
        if (contractTypes?.includes(GroupContractType.Cfm)) {
          return GroupTypeFilterValues.CfmCustomer;
        }
        return GroupTypeFilterValues.Customer;
      case GroupType.CfmRecycler:
        return GroupTypeFilterValues.CfmRecycler;
      case GroupType.CfmLogistics:
        return GroupTypeFilterValues.CfmLogistics;
      case GroupType.Admin:
        return GroupTypeFilterValues.Admin;
      case GroupType.PomAgency:
        return GroupTypeFilterValues.PomAgency;
      case GroupType.PomRepresentative:
        return GroupTypeFilterValues.PomRepresentative;
    }
  }

  public static groupTypeFilterValuesToGroupTypes(groupType: GroupTypeFilterValues | undefined): GroupType | undefined {
    switch (groupType) {
      case GroupTypeFilterValues.Admin:
        return GroupType.Admin;
      case GroupTypeFilterValues.CfmCustomer:
      case GroupTypeFilterValues.Pom:
      case GroupTypeFilterValues.PomAndCfmCustomer:
      case GroupTypeFilterValues.Customer:
        return GroupType.Customer;
      case GroupTypeFilterValues.CfmLogistics:
        return GroupType.CfmLogistics;
      case GroupTypeFilterValues.CfmRecycler:
        return GroupType.CfmRecycler;
      case GroupTypeFilterValues.PomAgency:
        return GroupType.PomAgency;
      case GroupTypeFilterValues.PomRepresentative:
        return GroupType.PomRepresentative;
    }
  }

  public static groupTypeFilterValuesToContractTypes(
    groupTypes: GroupTypeFilterValues | undefined,
  ): GroupContractType[] | undefined {
    switch (groupTypes) {
      case GroupTypeFilterValues.Pom:
        return [GroupContractType.Pom];
      case GroupTypeFilterValues.CfmLogistics:
      case GroupTypeFilterValues.CfmCustomer:
      case GroupTypeFilterValues.CfmRecycler:
        return [GroupContractType.Cfm];
      case GroupTypeFilterValues.PomAndCfmCustomer:
        return [GroupContractType.Cfm, GroupContractType.Pom];
      case GroupTypeFilterValues.PomAgency:
      case GroupTypeFilterValues.PomRepresentative:
      case GroupTypeFilterValues.Customer:
        return [];
    }
  }
}
