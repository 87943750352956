import { IAddress } from "../address/address";
import { IPaginationMeta } from "../pagination/pagination-meta";
import { IPaymentInfo } from "../payment-info/payment-info";
import { IUserLean } from "../user/user-lean";
import { IGroupBranch } from "./group-branch";
import { ICfmGroupContract, IPomGroupContract } from "./group-contract";
import { GroupType } from "./group-type";

export interface IGroup {
  id: number;
  uuid: string;
  name: string;
  name2: string | null;
  erpNumber?: string | undefined;
  type: GroupType;
  active?: Date | null;
  deleted?: Date | null;
  invoiceAddress: IAddress | null;
  groupAddress: IAddress | null;
  addresses?: IAddress[];
  users?: IUserLean[];
  earNumber?: string;
  earTemporaryNumber?: string;
  vatId?: string;
  agencyGroup?: IGroup | undefined;
  tradeRegisterNumber?: string;
  grsManagedEar: boolean;
  hasAcceptedSepaMandate: boolean;
  paymentInfo: IPaymentInfo | null | undefined;
  cfmContracts: ICfmGroupContract[] | null;
  pomContracts: IPomGroupContract[] | null;
  groupBranch: IGroupBranch | null | undefined;
  isPomAgencyAnnouncer: boolean;
  isAvalPartner: boolean | null;
}

export interface IGroupList {
  items: IGroup[];
  meta: IPaginationMeta;
}

export function hasGroupCfmContracts(group: IGroup): boolean {
  return group?.cfmContracts?.length ? group.cfmContracts.length > 0 : false;
}

export function hasGroupPomContracts(group?: IGroup): boolean {
  return group?.pomContracts?.length ? group.pomContracts.length > 0 : false;
}

export function hasGroupPomAndCfmContracts(group: IGroup): boolean {
  return hasGroupPomContracts(group) && hasGroupCfmContracts(group);
}

export function hasGroupPomOrCfmContracts(group: IGroup): boolean {
  return hasGroupPomContracts(group) || hasGroupCfmContracts(group);
}

export interface INextGroupErpNumber {
  nextErpNumber: number;
}

export interface ICreateGroupResult {
  group: IGroup;
  contactPersonEmailExists: boolean;
}
